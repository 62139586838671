import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const ContactForm = () => (
	<form
		name="contact"
		method="post"
		data-netlify="true"
		netlify-honeypot="bot-field"
		data-netlify-recaptcha="true"
		action="/thanks"
	>
		<input type="hidden" name="form-name" value="contact" />
		<div className="control">
			<div hidden>
				<label>
					Don’t fill this out: <input name="bot-field" />
				</label>
			</div>
			<input
				className="input"
				name="name"
				id="name"
				type="text"
				placeholder="Name"
				required
			/>
		</div>
		<div className="control">
			<input
				className="input"
				name="email"
				id="email"
				type="email"
				placeholder="Email"
				required
			/>
		</div>
		<div className="control">
			<input
				className="input"
				name="phone"
				id="phone"
				type="phone"
				placeholder="Phone"
				required
			/>
		</div>
		<div className="control">
			<textarea
				className="textarea"
				name="message"
				id="message"
				rows="5"
				placeholder="Message"
				required
			/>
		</div>
		<ReCAPTCHA
			sitekey={
				process.env.SITE_RECAPTCHA_KEY
					? process.env.SITE_RECAPTCHA_KEY
					: process.env.GATSBY_CAPTCHA_SITEKEY
			}
		/>
		<button type="submit" className="is-button" style={{ marginTop: `1.5rem` }}>
			SUBMIT
		</button>
	</form>
);

export default ContactForm;
