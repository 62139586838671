import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import ContactTemplate from '../templates/ContactTemplate';
import Helmet from 'react-helmet';

const ContactPage = ({ data }) => {
	const content = data.allMarkdownRemark.edges[0].node.frontmatter;
	return (
		<Layout>
			<Helmet>
				<title>Aeroseal Barrie | Contact</title>
			</Helmet>
			<ContactTemplate content={content} />
		</Layout>
	);
};

export default ContactPage;

export const query = graphql`
	{
		allMarkdownRemark(filter: { frontmatter: { slug: { eq: "contact" } } }) {
			edges {
				node {
					frontmatter {
						masthead_heading
						secondary_heading
						contact_heading
						contact_content
						quote_heading
						quote_content
					}
				}
			}
		}
	}
`;
