import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const QuoteForm = () => (
	<form
		name="request"
		method="post"
		data-netlify="true"
		netlify-honeypot="bot-field"
		data-netlify-recaptcha="true"
		action="/thanks"
	>
		<input type="hidden" name="form-name" value="request" />
		<div className="control">
			<div hidden>
				<label>
					Don’t fill this out: <input name="bot-field" />
				</label>
			</div>
			<input
				className="input"
				name="quote-name"
				id="quote-name"
				type="text"
				placeholder="Name"
				required
			/>
		</div>
		<div className="control">
			<input
				className="input"
				name="quote-email"
				id="quote-email"
				type="email"
				placeholder="Email"
				required
			/>
		</div>
		<div className="control">
			<input
				className="input"
				name="quote-phone"
				id="quote-phone"
				type="phone"
				placeholder="Phone"
				required
			/>
		</div>
		<div className="control">
			<input
				className="input"
				type="text"
				placeholder="Size of Building (sq. ft.)"
				required
				name="square-footage"
				id="square-footage"
			/>
		</div>
		<div className="control">
			<input
				className="input"
				type="text"
				placeholder="Age of Building"
				required
				name="building-age"
				id="building-age"
			/>
		</div>
		<div className="control">
			<input
				className="input"
				type="text"
				placeholder="Number of Furnaces"
				required
				name="furnace-number"
				id="furnace-number"
			/>
		</div>
		<div className="has-text-left" style={{ margin: '1.25rem 0 1rem 0' }}>
			<label>What issues are you experiencing? Choose all that apply: </label>
		</div>
		<div className="control" style={{ marginBottom: `1.5rem` }}>
			<fieldset>
				<label className="checkbox" htmlFor="uncomfortable">
					<input
						type="checkbox"
						value="✓"
						id="uncomfortable"
						name="uncomfortable"
					/>
					Uncomfortably hot or cold rooms
				</label>
				<br />
				<label className="checkbox" htmlFor="humidity">
					<input type="checkbox" value="✓" id="humidity" name="humidity" />
					Excessive humidity
				</label>
				<br />
				<label className="checkbox" htmlFor="dust">
					<input type="checkbox" value="✓" id="dust" name="dust" />
					Excessive dust
				</label>
				<br />
				<label className="checkbox" htmlFor="allergies">
					<input type="checkbox" value="✓" id="allergies" name="allergies" />
					Increased allergies indoors
				</label>
				<br />
				<label className="checkbox" htmlFor="odours">
					<input type="checkbox" value="✓" id="odours" name="odours" />
					Musty odours or fume smells
				</label>
				<br />
				<label className="checkbox" htmlFor="highbills">
					<input type="checkbox" value="✓" id="highbills" name="highbills" />
					High energy bills
				</label>
			</fieldset>
		</div>
		<ReCAPTCHA
			sitekey={
				process.env.SITE_RECAPTCHA_KEY
					? process.env.SITE_RECAPTCHA_KEY
					: process.env.GATSBY_CAPTCHA_SITEKEY
			}
		/>
		<button type="submit" className="is-button" style={{ marginTop: '2rem' }}>
			SUBMIT
		</button>
	</form>
);

export default QuoteForm;
