import React from 'react';
import { Link } from 'gatsby';
import footLogo from '../img/AerosealBarrie_Logo-01.svg';

const BotttomLogoSection = () => (
	<section className="section has-text-right">
		<div className="container">
			<Link to="/">
				<img
					src={footLogo}
					alt="Aeroseal Barrie logo"
					style={{ width: '224px' }}
				/>
			</Link>
		</div>
	</section>
);

export default BotttomLogoSection;
